// polyfill from https://developer.mozilla.org/de/docs/Web/API/ChildNode/remove
const patchPrototypes = [Element.prototype, CharacterData.prototype, DocumentType.prototype];
patchPrototypes.forEach((item: Element | CharacterData | DocumentType) => {
  if (item.hasOwnProperty('remove')) {
    return;
  }
  Object.defineProperty(item, 'remove', {
    configurable: true,
    enumerable: true,
    writable: true,
    value: function remove(): void {
      if (this.parentNode === null) {
        return;
      }
      this.parentNode.removeChild(this);
    }
  });
});
