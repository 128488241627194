/* eslint-disable import/order */
/* eslint-disable no-multiple-empty-lines */
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari \>= 10, Chrome \>= 55 (including Opera),
 * Edge \>= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. */
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';

// Polyfill for DomElement.remove()
import 'element.polyfill';

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';


/** Evergreen browsers require these. */
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.

/**
 * Required to enable the smooth scrolling option of Element.scrollIntoView
 * Needed for: All but Chrome and Firefox https://caniuse.com/#feat=scrollintoview
 */
import * as smoothscroll from 'smoothscroll-polyfill';

/**
 * core-js\@3 polyfill library for JavaScript's URL and URLSearchParams classes.
 */
import 'core-js/features/url';
import 'core-js/features/url-search-params';


/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * toMap addition for arrays allows converting an array to a map with a given key name
 */
import './extensions/array';

(window as any).global = window;
smoothscroll.polyfill();
