interface Array<T> {
  toMap(by: string | ((item: T) => string | number)): { [key: string]: T };
  split(by: ((item: T, index: number) => boolean)): { truthy: T[]; falsy: T[] };
  uniqBy(by: (value: T) => any): T[];
  uniq(): T[];
  difference(other: T[]): T[];
  sameContent(other: T[]): boolean;
  without(other: T[]): T[];
}

/**
 * Creates an object containing the array entries as values and the given field name
 * values as keys.
 *
 * @param by - The field name of the key. Should be unique in the array (will overwrite)
 * @returns An object
 */
Object.defineProperty(Array.prototype, 'toMap', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(by: string | ((item: T) => string | number)): { [key: string]: T } {
    return this.reduce((p: any, c: any) => {
      if (typeof by === 'string') {
        p[c[by]] = c;
      } else {
        p[by(c)] = c;
      }
      return p;
    }, {});
  }
});

/**
 * Splits an array into two by a predicate function
 *
 * @param by - The predicate function
 * @returns An object with two arrays, "truthy" containing all elements where the predicated returned true, and "falsy"
 * containing all elements where the predicate function did not return true.
 */
Object.defineProperty(Array.prototype, 'split', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(by: ((item: T, index: number) => boolean)): { truthy: T[]; falsy: T[] } {
    const truthy: T[] = [];
    const falsy: T[] = [];
    for (let i = 0; i < this.length; i++) {
      if (by(this[i], i)) {
        truthy.push(this[i]);
      } else {
        falsy.push(this[i]);
      }
    }
    return { truthy, falsy };
  }
});
/**
 * Removed duplicates from the array by using the callback equality comparator
 */
Object.defineProperty(Array.prototype, 'uniqBy', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(by: (value: T) => any): T[] {
    const valueArray = this.map(by);
    return this.filter((elem: T, index: number) => valueArray.indexOf(by(elem)) === index);
  }
});

/**
 * Removes duplicate elements from the array by value equality (===)
 */
Object.defineProperty(Array.prototype, 'uniq', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(): T[] {
    return this.filter((v: T, i: number) => this.indexOf(v) === i);
  }
});

/**
 * Returns an array of entries that are only present in one of the given arrays
 */
Object.defineProperty(Array.prototype, 'difference', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(other: T[]): T[] {
    return [...this.filter((e: T) => other.indexOf(e) < 0), ...other.filter((e: T) => this.indexOf(e) < 0)];
  }
});

/**
 * Returns true if both arrays contain the same elements, disregarding element order
 */
Object.defineProperty(Array.prototype, 'sameContent', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(other: T[]): boolean {
    return this.length === other.length && this.every((entry: T) => other.indexOf(entry) > -1);
  }
});

/**
 * Returns a new array where all elements that occur in the other array are removed
 */
Object.defineProperty(Array.prototype, 'without', {
  configurable: false,
  enumerable: false,
  writable: false,
  value: function <T>(other: T[]): T[] {
    return this.filter((e: T) => other.indexOf(e) < 0);
  }
});
